import "./Card.scss";

interface CardProps {
  title: string;
  link: string;
  additionalTitle: string;
}

const Card = ({ title, link, additionalTitle }: CardProps) => {
  return (
    <a href={link} className="card">
      <h2>{title}</h2>
      <p>{additionalTitle}</p>
    </a>
  );
};

export default Card;
