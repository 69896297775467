import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./Error.scss";
import defaultContent from "../../content/error";
import { ReactComponent as ServerDownLightImg } from "../../common/icons/IconServerDown/svg/server_down_light.svg";
import { ReactComponent as SessionExpiryLightImg } from "../../common/icons/IconSessionExpiry/svg/session_expiry_light.svg";
import useLogin from "../CustomHooks/useLogin";

type ComponentProps = {
  error: any;
};

const MaintenanceComponent = () => {
  const { t } = useTranslation(["header"]);

  return (
    <div>
      <div className="error_container">
        <div className="error_image_section">
          <ServerDownLightImg />
        </div>
        <div className="error_message_section">
          <div className="error_message_1"> {`${t("downForMaintenance", defaultContent["downForMaintenance"])}`} </div>
          <div className="error_message_2"> {`${t("checkBackSoon", defaultContent["checkBackSoon"])}`} </div>
        </div>
      </div>
    </div>
  );
};

const AuthenticationComponent = () => {
  const { t } = useTranslation(["header"]);
  const navigate = useNavigate();
  const { onButtonClick, isLoading, isError } = useLogin();
  return (
    <div>
      <div className="error_container">
        <div className="error_image_section">
          <SessionExpiryLightImg />
        </div>
        <div className="error_message_section">
          <div className="error_message_1"> {`${t("sessionExpiredMsg", defaultContent["sessionExpiredMsg"])}`} </div>
          <div className="error_message_2">{`${t("signInAgain", defaultContent["signInAgain"])}`} </div>
        </div>
        <div>
          <button className="login-button" onClick={() => navigate("/")}>
            {`${t("login", defaultContent["login"])}`}{" "}
          </button>
        </div>
      </div>
    </div>
  );
};

const ErrorMapping = {
  maintenanceMode: <MaintenanceComponent />,
  authentication: <AuthenticationComponent />,
};

const ErrorComponent: React.FC<ComponentProps> = ({ error }) => {
  type ErrorCode = keyof typeof ErrorMapping;
  const errorCode: ErrorCode = error;
  const value = ErrorMapping[errorCode];
  return <>{value}</>;
};

export default ErrorComponent;
