import "./Dashboard.scss";
import Card from "../../common/components/Card/Card";

export const Dashboard = () => {
  return (
    <div className="container">
      <div className="card-container">
        <Card title="Admin" link="/admin" additionalTitle="" />
        <Card title="Account Management" link="#" additionalTitle="Coming soon..." />
        <Card title="BCR" link="#" additionalTitle="Coming soon..." />
        <Card title="HATV" link="#" additionalTitle="Coming soon..." />
      </div>
    </div>
  );
};

export default Dashboard;
