import "./SessionList.scss";

import defaultContent from "../../../content/sessionList";
import { useTranslation } from "react-i18next";
import Title from "../../../common/components/Title/Title";
import Button from "../../../common/components/Button/Button";
import Search from "../../../common/components/Search/Search";
import Table from "../../../common/components/Table/Table";
import { mockSessionListTableData, mockSessionListTableHeader } from "../../../mocks/data";

export const SessionList = () => {
  const { t } = useTranslation(["sessionList"]);
  const title = t("sessionList", defaultContent["sessionList"]).toString();

  const sessionList = (
    <div className="container">
      <Title text={title} />
      <div className="h-3"></div>
      <div className="row">
        <div className="column-one right">
          <span className="w-3">
            <Search
              onSearch={function (query: string): void {
                throw new Error("Function not implemented.");
              }}
            />
          </span>
          <span className="spacing-half-one"></span>

          <Button
            text="Bulk Process"
            color="primary-black"
            disabled={false}
            action={function (): void {
              alert("Function not implemented.");
            }}
          />
        </div>
      </div>
      <div className="h-2"></div>
      <Table headers={mockSessionListTableHeader} data={mockSessionListTableData} />
    </div>
  );

  return <>{sessionList}</>;
};

export default SessionList;
