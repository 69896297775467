import { HttpStatusCode } from "axios";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import config from "../../../config";
import authService from "../../../utils/auth.service";
import axiosInstance from "../../../interceptors/axios.interceptor";

export const TokenHandler = () => {
  const [queryParameters] = useSearchParams();

  useEffect(() => {
    const getTokenExchange = () => {
      const lgsAuthorizationCode = queryParameters.get("code");
      const id = queryParameters.get("id");
      const url = `${config.appUrl}/auth/token?code=${lgsAuthorizationCode}&id=${id}`;

      try {
        axiosInstance.get(url).then((res) => {
          if (res && res.data && res.data.statusCode === HttpStatusCode.Ok) {
            const token = authService.handleTokenResponse(res.data);
            if (token) {
              window.location.href = "/dashboard";
            } else {
              window.location.href = "/login";
            }
          }
        });
      } catch (err) {
        console.log(`Get token exchange error: ${err}`);
      }
    };

    getTokenExchange();
  }, []);

  return <div>Loading....</div>;
};
