import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { configureStore, PreloadedState } from "@reduxjs/toolkit";
import { FLUSH, PAUSE, PERSIST, persistStore, PURGE, REGISTER, REHYDRATE } from "redux-persist";
import rootReducer from "../reducers";

// https://stackoverflow.com/questions/66315413/type-definitions-for-redux-toolkit-store-with-preloadedstate
export const setupStore = (preloadedState?: PreloadedState<any>) =>
  configureStore({
    reducer: rootReducer,
    ...preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
  });

export type Store = ReturnType<typeof setupStore>;
export type RootState = ReturnType<Store["getState"]>;
export type AppDispatch = Store["dispatch"];

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// setup store
export const store = setupStore();

// persist redux store so that the state gets rehydrated on page refresh
export const persistor = persistStore(store);
