import "./UserDelegationDetail.scss";

import defaultContent from "../../../content/userDelegationDetail";
import { useTranslation } from "react-i18next";
import Title from "../../../common/components/Title/Title";
import Button from "../../../common/components/Button/Button";

import SelectComponent from "../../../common/components/Select/Select";
import { mockStatus, mockUser } from "../../../mocks/data";

export const UserDelegationDetail = () => {
  const { t } = useTranslation(["userDelegationDetail"]);
  const title = t("userDelegationDetail", defaultContent["userDelegationDetail"]).toString();

  const userDelegationDetail = (
    <div className="container">
      <Title text={title} />
      <div className="h-3"></div>
      <div className="row">
        <div className="column-three">
          <SelectComponent isMulti={false} label={"User Name"} options={mockUser} />
        </div>
        <div className="column-three">
          <SelectComponent isMulti={false} label={"Delegated User"} options={mockUser} />
        </div>
        <div className="column-three">
          <SelectComponent isMulti={false} label={"Status"} options={mockStatus} />
        </div>
      </div>
      <div className="h-2"></div>
      <div className="row">
        <div className="column-one">
          <Button
            text="Save Changes"
            color="primary-purple"
            disabled={false}
            action={function (): void {
              throw new Error("Function not implemented.");
            }}
          />
          <span className="spacing-one"></span>
          <Button
            text="Cancel"
            color="primary-white"
            disabled={false}
            action={function (): void {
              throw new Error("Function not implemented.");
            }}
          />
        </div>
      </div>
    </div>
  );

  return <>{userDelegationDetail}</>;
};

export default UserDelegationDetail;
