import "./PermissionRoleDetail.scss";

import defaultContent from "../../../content/permissionRoleDetail";
import { useTranslation } from "react-i18next";
import Title from "../../../common/components/Title/Title";
import Section from "../../../common/components/Section/Section";
import { Input } from "../../../common/components/Input/Input";
import Search from "../../../common/components/Search/Search";
import Button from "../../../common/components/Button/Button";
import Table from "../../../common/components/Table/Table";
import SelectComponent from "../../../common/components/Select/Select";
import {
  mockApplication,
  mockPermissionRoleDetailListTableData,
  mockPermissionRoleDetailListTableHeader,
  mockStatus,
} from "../../../mocks/data";

export const PermissionRoleDetail = () => {
  const { t } = useTranslation(["permissionRoleDetail"]);
  const title = t("permissionRoleDetail", defaultContent["permissionRoleDetail"]).toString();

  const permissionRoleDetail = (
    <div className="container">
      <Title text={title} />
      <div className="h-3"></div>
      <div className="row">
        <div className="column-one">
          <Section text={"User Information"} />
        </div>
      </div>
      <div className="h-1"></div>
      <div className="row">
        <div className="column-two">
          <Input label={"Role Name"} readOnly={false} />
        </div>
        <div className="column-two">
          <SelectComponent isMulti={false} label={"Status"} options={mockStatus} />
        </div>
      </div>
      <div className="h-1"></div>
      <div className="row">
        <div className="column-one">
          <SelectComponent isMulti={false} label={"Select Application"} options={mockApplication} />
        </div>
      </div>
      <div className="h-3"></div>
      <div className="row">
        <div className="column-one right">
          <span className="w-3">
            <Search
              onSearch={function (query: string): void {
                throw new Error("Function not implemented.");
              }}
            />
          </span>

          <span className="spacing-half-one"></span>
          <Button
            text="Add Feature"
            color="primary-black"
            disabled={false}
            action={function (): void {
              alert("Function not implemented.");
            }}
          />
          <span className="spacing-half-one"></span>

          <Button
            text="Bulk Process"
            color="primary-black"
            disabled={false}
            action={function (): void {
              alert("Function not implemented.");
            }}
          />
        </div>
      </div>
      <div className="h-2"></div>
      <Table headers={mockPermissionRoleDetailListTableHeader} data={mockPermissionRoleDetailListTableData} />
      <div className="h-3"></div>
      <div className="row">
        <div className="column-one">
          <Button
            text="Save Changes"
            color="primary-purple"
            disabled={false}
            action={function (): void {
              throw new Error("Function not implemented.");
            }}
          />
          <span className="spacing-one"></span>
          <Button
            text="Cancel"
            color="primary-white"
            disabled={false}
            action={function (): void {
              throw new Error("Function not implemented.");
            }}
          />
        </div>
      </div>
      <div className="h-3"></div>
    </div>
  );

  return <>{permissionRoleDetail}</>;
};

export default PermissionRoleDetail;
