import axios, { HttpStatusCode } from "axios";
import config from "../config";
import { QUERY_STATUS } from "../constants";
import authService from "../utils/auth.service";

const axiosInstance = axios.create({
  baseURL: `${config.appUrl}`,
  validateStatus: function (status) {
    return status >= 200 && status < 400;
  },
});

let tokenRefreshInProgress: boolean = false;

export const setupInterceptors = (history: any) => {
  axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem("auth-accessToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.withCredentials = true;
    if (config.url && authService.isAccessTokenApiUrl(config.url)) {
      if (!tokenRefreshInProgress) {
        if (authService.isAccessTokenNeedsRefresh()) {
          refreshAccessToken();
        } else if (authService.isSessionTokenNeedsRefresh()) {
          refreshSessionToken();
        } else if (!token && !authService.isAuthenticated()) {
          authService.clearAuth();
          window.location.href = "/error";
        } else if (!authService.isAuthenticated()) {
          authService.clearAuth();
          window.location.href = "/logout";
        }
      } else {
        console.log(`outside tokenRefreshInProgress: ${tokenRefreshInProgress}`);
      }
    }
    return config;
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      if (!response.config.url?.includes("/auth")) {
        return response;
      }
      if (
        response &&
        response.data &&
        response.data.statusCode === HttpStatusCode.Ok &&
        response.data.body &&
        !response.data.body.status
      ) {
        window.location.href = "/maintenance";
      }
      if (response && response.data && response.data.statusCode === HttpStatusCode.Unauthorized) {
        console.log("invalid token returned, logging out");
        window.location.href = "/logout";
      }
      if (response && response.data && response.data.head && response.data.head.authentication) {
        const auth = response.data.head.authentication;
        if (auth.loginSessionStatus !== QUERY_STATUS.ACTIVE && auth.userSessionStatus !== QUERY_STATUS.ACTIVE) {
          console.log("invalid token returned, head to logging out");
          window.location.href = "/logout";
        }
      }
      return response;
    },
    (error) => {
      if (error && error.response && error.response.status === HttpStatusCode.Unauthorized) {
        console.log("401 detected - logging out");
        window.location.href = "/logout";
      }
    },
  );

  const refreshAccessToken = () => {
    tokenRefreshInProgress = true;
    const url = `${config.appUrl}/auth/refresh/token`;
    try {
      axiosInstance.get(url).then((res) => {
        if (res && res.data && res.data.statusCode === HttpStatusCode.Ok && res.data.body) {
          tokenRefreshInProgress = false;
          localStorage.setItem("auth-accessToken", res.data.body);
        }
      });
    } catch (err) {
      console.log(`Refresh access token error: ${err}`);
      authService.clearAuth();
      tokenRefreshInProgress = false;
    }
  };

  const refreshSessionToken = () => {
    tokenRefreshInProgress = true;
    const url = `${config.appUrl}/auth/refresh/session`;
    try {
      axiosInstance.get(url).then((res) => {
        if (res && res.data && res.data.statusCode === HttpStatusCode.Ok && res.data.body) {
          tokenRefreshInProgress = false;
          localStorage.setItem("auth-accessToken", res.data.body);
        }
      });
    } catch (err) {
      console.log(`Refresh session token error: ${err}`);
      authService.clearAuth();
      tokenRefreshInProgress = false;
    }
  };
};

export default axiosInstance;
