import "./PlatformDetail.scss";

import defaultContent from "../../../content/platformDetail";
import { useTranslation } from "react-i18next";
import Title from "../../../common/components/Title/Title";
import { Input } from "../../../common/components/Input/Input";
import Button from "../../../common/components/Button/Button";
import SelectComponent from "../../../common/components/Select/Select";
import { mockApplication, mockStatus } from "../../../mocks/data";

export const PlatformDetail = () => {
  const { t } = useTranslation(["platformDetail"]);
  const title = t("platformDetail", defaultContent["platformDetail"]).toString();

  // Separate constant for the container content
  const platformDetail = (
    <div className="container">
      <Title text={title} />
      <div className="h-3"></div>
      <div className="row">
        <div className="column-three">
          <Input label={"Platform Name"} readOnly={false} />
        </div>
        <div className="column-three">
          <SelectComponent isMulti={false} label={"Assigned Application"} options={mockApplication} />
        </div>
        <div className="column-three">
          <SelectComponent isMulti={false} label={"Status"} options={mockStatus} />
        </div>
      </div>
      <div className="h-2"></div>
      <div className="row">
        <div className="column-one">
          <Button
            text="Save Changes"
            color="primary-purple"
            disabled={false}
            action={function (): void {
              throw new Error("Function not implemented.");
            }}
          />
          <span className="spacing-one"></span>
          <Button
            text="Cancel"
            color="primary-white"
            disabled={false}
            action={function (): void {
              throw new Error("Function not implemented.");
            }}
          />
        </div>
      </div>
    </div>
  );

  return <>{platformDetail}</>;
};

export default PlatformDetail;
