import { Option } from "../common/components/Select/Select";

export const mockStatus: Option[] = [
  { value: "Active", label: "Active", title: "Active", type: "active" },
  { value: "Inactive", label: "Inactive", title: "Inactive", type: "inactive" },
];

export const mockApplication: Option[] = [
  { value: "Account Management", label: "Account Management", title: "Account Management", type: "Account Management" },
  { value: "BCR", label: "BCR", title: "BCR", type: "BCR" },
  { value: "HATV", label: "HATV", title: "HATV", type: "HATV" },
];

export const mockPlatform: Option[] = [
  { value: "Desktop", label: "Desktop", title: "Desktop", type: "Desktop" },
  { value: "Mobile Web", label: "Mobile Web", title: "Mobile Web", type: "Mobile Web" },
];

export const mockFeatures: Option[] = [
  { value: "Feature A", label: "Feature A", title: "Feature A", type: "Feature A" },
  {
    value: "Feature B",
    label: "Feature B",
    title: "Feature B",
    type: "Feature B",
  },
  { value: "Feature C", label: "Feature C", title: "Feature C", type: "Feature C" },
];

export const mockGroups: Option[] = [
  { value: "Group A", label: "Group A", title: "Group A", type: "Group A" },
  {
    value: "Group B",
    label: "Group B",
    title: "Group B",
    type: "Group B",
  },
  { value: "Group C", label: "Group C", title: "Group C", type: "Group C" },
];

export const mockRoles: Option[] = [
  { value: "Role A", label: "Role A", title: "Role A", type: "Role A" },
  {
    value: "Role B",
    label: "Role B",
    title: "Role B",
    type: "Role B",
  },
  { value: "Role C", label: "Role C", title: "Role C", type: "Role C" },
];

export const mockUser: Option[] = [
  { value: "User A", label: "User A", title: "User A", type: "User A" },
  {
    value: "User B",
    label: "User B",
    title: "User B",
    type: "User B",
  },
  { value: "User C", label: "User C", title: "User C", type: "User C" },
];

export const mockTeam: Option[] = [
  { value: "Team A", label: "Team A", title: "Team A", type: "Team A" },
  {
    value: "Team B",
    label: "Team B",
    title: "Team B",
    type: "Team B",
  },
  { value: "Team C", label: "Team C", title: "Team C", type: "Team C" },
];

export const mockMemberType: Option[] = [
  { value: "Lead Member", label: "Lead Member", title: "Lead Member", type: "Lead Member" },
  { value: "Team Member", label: "Team Member", title: "Team Member", type: "Team Member" },
];

export const mockUserListTableHeader = ["", "Account Name", "ABO Number", "Email", "Team", "Upline", "Status"];
export const mockUserListTableData = [
  ["", "A", "", "", "", "", "Active"],
  ["", "B", "", "", "", "", "Active"],
];

export const mockUserDelegationListTableHeader = ["", "Account Name", "Delegated User", "Status"];
export const mockUserDelegationListTableData = [
  ["", " A", "C", "Active"],
  ["", " B", "C", "Active"],
  ["", " C", "", "Active"],
];

export const mockTeamListTableHeader = ["", "Team Name", "Status"];
export const mockTeamListTableData = [
  ["", "Team A", "Active"],
  ["", "Team B", "Active"],
];

export const mockSessionListTableHeader = ["", "Session Login", "Session User", "Application", "Platform", "Status"];
export const mockSessionListTableData = [
  ["", "MYU07962", "Admin", "", "", "Active"],
  ["", "MYA07962", "Admin", "", "", "Active"],
];

export const mockPlatformListTableHeader = ["", "Platform Name", "Application", "Status"];
export const mockPlatformListTableData = [
  ["", "Desktop", "Admin", "Active"],
  ["", "Mobile", "Admin", "Active"],
];

export const mockPermissionRoleListTableHeader = [
  "",
  "Role Name",
  "Application",
  "Platform",
  "Features",
  "Groups",
  "Status",
];
export const mockPermissionRoleListTableData = [
  ["", "Role A", "", "", "Many", "Many", "Active"],
  ["", "Role B", "", "", "", "", "Active"],
  ["", "Role C", "", "", "", "", "Active"],
  ["", "Role D", "", "", "", "", "Active"],
];

export const mockPermissionRoleGroupListTableHeader = ["", "Permission Group Name", "Status"];
export const mockPermissionRoleGroupListTableData = [
  ["", "Group A", "Active"],
  ["", "Group B", "Active"],
  ["", "Group C", "Active"],
  ["", "Group D", "Active"],
];

export const mockPermissionRoleDetailListTableHeader = [
  "",
  "Feature Name",
  "Application",
  "Platform",
  "Create",
  "Read",
  "Update",
  "Delete",
  "Status",
];
export const mockPermissionRoleDetailListTableData = [
  ["", "Feature A", "-", "-", "-", "-", "-", "-", "Active"],
  ["", "Feature B", "", "", "", "", "", "", "Active"],
  ["", "Feature C", "", "", "", "", "", "", "Active"],
  ["", "Feature D", "", "", "", "", "", "", "Active"],
];

export const mockPermissionFeatureListTableHeader = ["", "Permission Feature", "Status"];
export const mockPermissionFeatureListTableData = [
  ["", "Feature A", "Active"],
  ["", "Feature B", "Active"],
  ["", "Feature C", "Active"],
  ["", "Feature D", "Active"],
];

export const mockApplicationListTableHeader = ["", "Application Name", "Status"];
export const mockApplicationListTableData = [
  ["", "HATV", "Active"],
  ["", "Account Management", "Active"],
  ["", "Administrator", "Active"],
  ["", "BCR", "Active"],
];
