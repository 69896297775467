import { useEffect, useState } from "react";
import { Outlet } from "react-router";
import { HttpStatusCode } from "axios";
import config from "../../../config";
import axiosInstance from "../../../interceptors/axios.interceptor";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";

interface LayoutProps {
  showHeader?: boolean;
  showNavigation?: boolean;
  user?: string;
}

const LandingLayout: React.FC<LayoutProps> = ({ showHeader = false, showNavigation = false, user = "" }) => {
  const [loggedUser, setLoggedUser] = useState("");

  useEffect(() => {
    const getActiveUsername = () => {
      let username = "";
      const nativeId = localStorage.getItem("auth-nativeId");
      if (nativeId) {
        const url = `${config.appUrl}/auth/user/native/${nativeId}`;
        axiosInstance
          .get(url)
          .then((res) => {
            if (res && res.data && res.data.statusCode === HttpStatusCode.Ok && res.data.body) {
              setLoggedUser(res.data.body.fullName);
            }
          })
          .catch((err) => {
            console.log(`Get user by native id: ${nativeId} error: ${err}`);
          });
      }

      return username;
    };

    getActiveUsername();
  }, []);

  return (
    <>
      <Header showNavigation={showNavigation} showHeader={showHeader} loggedUser={loggedUser} />
      <div className="content">
        <Outlet />
      </div>
      <Footer />
    </>
  );
};

export default LandingLayout;
