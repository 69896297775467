import "./ApplicationDetail.scss";

import defaultContent from "../../../content/applicationDetail";
import { useTranslation } from "react-i18next";
import Title from "../../../common/components/Title/Title";
import { Input } from "../../../common/components/Input/Input";
import Button from "../../../common/components/Button/Button";
import SelectComponent from "../../../common/components/Select/Select";
import { mockStatus } from "../../../mocks/data";

export const ApplicationDetail = () => {
  const { t } = useTranslation(["applicationDetail"]);
  const title = t("applicationDetail", defaultContent["applicationDetail"]).toString();

  const applicationDetail = (
    <div className="container">
      <Title text={title} />
      <div className="h-3"></div>
      <div className="row">
        <div className="column-two">
          <Input label={"Application Name"} readOnly={false} />
        </div>
        <div className="column-two">
          <SelectComponent isMulti={false} label={"Status"} options={mockStatus} />
        </div>
      </div>
      <div className="h-2"></div>
      <div className="row">
        <div className="column-one">
          <Button
            text="Save Changes"
            color="primary-purple"
            disabled={false}
            action={function (): void {
              throw new Error("Function not implemented.");
            }}
          />
          <span className="spacing-one"></span>
          <Button
            text="Cancel"
            color="primary-white"
            disabled={false}
            action={function (): void {
              throw new Error("Function not implemented.");
            }}
          />
        </div>
      </div>
    </div>
  );

  return <>{applicationDetail}</>;
};

export default ApplicationDetail;
