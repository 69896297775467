import "./Textarea.scss";

interface TextareaProps {
  label: string;
  value?: string;
  placeholder?: string;
  readOnly: boolean;
  rows?: number;
}

export const Textarea = ({ label, value, placeholder, readOnly, rows = 4 }: TextareaProps) => {
  return (
    <div className="textarea">
      <label className="textarea__label">{label}</label>
      <textarea className="textarea__form" value={value} placeholder={placeholder} readOnly={readOnly} rows={rows} />
    </div>
  );
};
