import "./PermissionRoleFeatureAssign.scss";

import defaultContent from "../../../content/permissionRoleFeatureAssign";
import { useTranslation } from "react-i18next";
import Title from "../../../common/components/Title/Title";
import Section from "../../../common/components/Section/Section";
import { Input } from "../../../common/components/Input/Input";
import Button from "../../../common/components/Button/Button";
import SelectComponent from "../../../common/components/Select/Select";
import { mockFeatures } from "../../../mocks/data";

export const PermissionRoleFeatureAssign = () => {
  const { t } = useTranslation(["permissionRoleFeatureAssign"]);
  const title = t("permissionRoleFeatureAssign", defaultContent["permissionRoleFeatureAssign"]).toString();

  const permissionRoleFeatureAssign = (
    <div className="container">
      <Title text={title} />
      <div className="h-3"></div>
      <div className="row">
        <div className="column-one">
          <Section text={"User Information"} />
        </div>
      </div>
      <div className="h-1"></div>
      <div className="row">
        <div className="column-one">
          <Input label={"Role Name"} readOnly={false} />
        </div>
      </div>
      <div className="h-1"></div>
      <div className="row">
        <div className="column-one">
          <SelectComponent isMulti={true} label={"Features"} options={mockFeatures} />
        </div>
      </div>
      <div className="h-2"></div>
      <div className="row">
        <div className="column-one">
          <Button
            text="Save Changes"
            color="primary-purple"
            disabled={false}
            action={function (): void {
              throw new Error("Function not implemented.");
            }}
          />
          <span className="spacing-one"></span>
          <Button
            text="Cancel"
            color="primary-white"
            disabled={false}
            action={function (): void {
              throw new Error("Function not implemented.");
            }}
          />
        </div>
      </div>
    </div>
  );

  return <>{permissionRoleFeatureAssign}</>;
};

export default PermissionRoleFeatureAssign;
