export const QUERY_STATUS = {
  ACTIVE: 1,
  EXPIRED: 2,
  SUSPENDED: 2,
  REVOKED: 3,
  LOGOUT: 9,
  INACTIVE: 9,
};

export const ERROR_TYPE = {
  maintenanceMode: "maintenanceMode",
  authentication: "authentication",
};
