import "./Footer.scss";
import defaultContent from "../../../content/footer";
import { useTranslation } from "react-i18next";

export const Footer = () => {
  const { t } = useTranslation(["footer"]);
  const currentYear = new Date().getFullYear();

  return (
    <footer id="footer" className="footer">
      <div>
        <div className="footer__footer-links">
          <span>
            <a
              href="https://www.amwayglobal.com/privacy-notice/united-states/?tab=abo"
              id="privacy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Notice
            </a>
            <span className="footer__divider"></span>
          </span>
          <span>
            <a
              href="https://www.amway.com/en_US/support-center/policies-and-terms#TermsofUseSubcategoryComponent"
              id="terms"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Use
            </a>
          </span>
        </div>
        <div className="copyright" id="copyright">
          {currentYear} {`${t("copyright", defaultContent["copyright"])}`}
        </div>
        <div id="confidential">{`${t("confidential", defaultContent["confidential"])}`}</div>
        <div id="aboOnly">{`${t("adminOnlyLabel", defaultContent["adminOnlyLabel"])}`}</div>
      </div>
    </footer>
  );
};
