import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import authService from "../utils/auth.service";

const AuthenticatedRoute = ({ element = <></> }) => {
  const isAuthenticated = authService.isAuthenticated();
  return isAuthenticated ? element : <Navigate to="/login" />;
};

AuthenticatedRoute.propTypes = {
  element: PropTypes.element.isRequired,
};

export default AuthenticatedRoute;
