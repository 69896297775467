import "./Sidebar.scss";
import { useTranslation } from "react-i18next";

const sidebarContent = [
  { label: "dashboard", link: "dashboard" },
  {
    label: "user",
    link: null,
    subItems: [
      { label: "users", link: "users" },
      { label: "teams", link: "teams" },
      { label: "delegations", link: "delegations" },
    ],
  },
  {
    label: "permission",
    link: null,
    subItems: [
      { label: "roles", link: "roles" },
      { label: "groups", link: "groups" },
      { label: "features", link: "features" },
    ],
  },
  { label: "session", link: "session" },
  { label: "application", link: "application" },
  { label: "platform", link: "platform" },
];

const RenderSidebarItem = ({ item }: any) => {
  const { t } = useTranslation(["sidebar"]);

  return (
    <li>
      {item.link ? (
        t(item.label)
      ) : (
        <>
          {t(item.label)}
          <ul>
            {item.subItems?.map((subItem: any, index: any) => (
              <RenderSidebarItem key={index} item={subItem} />
            ))}
          </ul>
        </>
      )}
    </li>
  );
};

const RenderSidebarNavigation = () => (
  <div className="sidebar-navigation">
    <nav>
      <ul>
        {sidebarContent.map((item, index) => (
          <RenderSidebarItem key={index} item={item} />
        ))}
      </ul>
    </nav>
  </div>
);

export const Sidebar = () => (
  <div className="sidebar">
    <RenderSidebarNavigation />
  </div>
);

export default Sidebar;
