// Button.js
import "./Section.scss"; // Import the SCSS stylesheet

type Props = {
  text: string;
};

const Section = ({ text }: Props) => {
  return (
    <div className="section">
      <h2>{text}</h2>
    </div>
  );
};

export default Section;
