import "./PermissionRoleGroupAssign.scss";

import defaultContent from "../../../content/permissionRoleGroupAssign";
import { useTranslation } from "react-i18next";
import Title from "../../../common/components/Title/Title";
import { Input } from "../../../common/components/Input/Input";
import Button from "../../../common/components/Button/Button";
import SelectComponent from "../../../common/components/Select/Select";
import { mockGroups } from "../../../mocks/data";

export const PermissionRoleGroupAssign = () => {
  const { t } = useTranslation(["PermissionRoleGroupAssign"]);
  const title = t("permissionRoleGroupAssign", defaultContent["permissionRoleGroupAssign"]).toString();

  const permissionRoleGroupAssign = (
    <div className="container">
      <Title text={title} />
      <div className="h-3"></div>
      <div className="row">
        <div className="column-one">
          <Input label={"Role Name"} readOnly={false} />
        </div>
      </div>
      <div className="h-1"></div>
      <div className="row">
        <div className="column-one">
          <SelectComponent isMulti={true} label={"Role Groups"} options={mockGroups} />
        </div>
      </div>
      <div className="h-2"></div>
      <div className="row">
        <div className="column-one">
          <Button
            text="Save Changes"
            color="primary-purple"
            disabled={false}
            action={function (): void {
              throw new Error("Function not implemented.");
            }}
          />
          <span className="spacing-one"></span>
          <Button
            text="Cancel"
            color="primary-white"
            disabled={false}
            action={function (): void {
              throw new Error("Function not implemented.");
            }}
          />
        </div>
      </div>
    </div>
  );

  return <>{permissionRoleGroupAssign}</>;
};

export default PermissionRoleGroupAssign;
