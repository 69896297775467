import { useState, useEffect } from "react";
import { HttpStatusCode } from "axios";
import axiosInstance from "../../interceptors/axios.interceptor";
import config from "../../config";

export const useServiceAvailability = () => {
  const [isServiceAvailable, setServiceAvailable] = useState<boolean | null>(true);

  useEffect(() => {
    const checkServiceAvailability = async () => {
      try {
        const url = `${config.appUrl}/admin/availability`;
        const res = await axiosInstance.get(url);
        if (res && res.data && res.data.statusCode === HttpStatusCode.Ok && res.data.body && res.data.body.status) {
          setServiceAvailable(res.data.body.status);
        } else {
          setServiceAvailable(false);
        }
      } catch (err) {
        console.error(`Check service availability error : ${err}`);
        setServiceAvailable(false);
      }
    };

    checkServiceAvailability();
  }, [isServiceAvailable]);

  return { isServiceAvailable };
};
