import { BrowserRouter, useRoutes } from "react-router-dom";
import { InjectAxiosInterceptors } from "../interceptors";
import { ERROR_TYPE } from "../constants";
import LandingLayout from "../common/components/Layout/LandingLayout";
import AuthenticatedRoute from "./AuthenticatedRoute";
import Login from "../components/Auth/Login/Login";
import { TokenHandler } from "../components/Auth/TokenHandler/TokenHandler";
import Dashboard from "../components/Dashboard/Dashboard";
import Admin from "../components/Admin/Admin";
import UserList from "../components/User/UserList/UserList";
import UserDetail from "../components/User/UserDetail/UserDetail";
import UserDelegationList from "../components/User/UserDelegationList/UserDelegationList";
import UserDelegationDetail from "../components/User/UserDelegationDetail/UserDelegationDetail";
import TeamList from "../components/Team/TeamList/TeamList";
import TeamDetail from "../components/Team/TeamDetail/TeamDetail";
import PermissionRoleList from "../components/Permission/PermissionRoleList/PermissionRoleList";
import PermissionRoleDetail from "../components/Permission/PermissionRoleDetail/PermissionRoleDetail";
import PermissionRoleFeatureAssign from "../components/Permission/PermissionRoleFeatureAssign/PermissionRoleFeatureAssign";
import PermissionRoleGroupAssign from "../components/Permission/PermissionRoleGroupAssign/PermissionRoleGroupAssign";
import PermissionRoleGroupList from "../components/Permission/PermissionRoleGroupList/PermissionRoleGroupList";
import PermissionRoleGroupDetail from "../components/Permission/PermissionRoleGroupDetail/PermissionRoleGroupDetail";
import PermissionFeatureList from "../components/Permission/PermissionFeatureList/PermissionFeatureList";
import PermissionFeatureDetail from "../components/Permission/PermissionFeatureDetail/PermissionFeatureDetail";
import ApplicationList from "../components/Application/ApplicationList/ApplicationList";
import ApplicationDetail from "../components/Application/ApplicationDetail/ApplicationDetail";
import PlatformList from "../components/Platform/PlatformList/PlatformList";
import PlatformDetail from "../components/Platform/PlatformDetail/PlatformDetail";
import SessionList from "../components/Session/SessionList/SessionList";
import SessionDetail from "../components/Session/SessionDetail/SessionDetail";
import ErrorComponent from "../components/Error/Error";

/**
 * This is a function that defines the AppRoute component.
 *
 * @return {ReactNode} The routes for the AppRoute component.
 */
const AppRoute = ({ isAuthenticated = false }) => {
  let routes = useRoutes([
    {
      element: <AuthenticatedRoute element={<LandingLayout showHeader user={""} />} />,
      children: [{ path: "dashboard", element: <Dashboard /> }],
    },
    {
      element: <AuthenticatedRoute element={<LandingLayout showNavigation showHeader user={""} />} />,
      children: [
        { path: "admin", element: <Admin /> },
        { path: "user", element: <UserList /> },
        { path: "user/detail", element: <UserDetail /> },
        { path: "user/delegation", element: <UserDelegationList /> },
        { path: "user/delegation/detail", element: <UserDelegationDetail /> },
        { path: "team", element: <TeamList /> },
        { path: "team/detail", element: <TeamDetail /> },
        { path: "permission/role/list", element: <PermissionRoleList /> },
        { path: "permission/role/detail", element: <PermissionRoleDetail /> },
        { path: "permission/role/assign-feature", element: <PermissionRoleFeatureAssign /> },
        { path: "permission/role/assign-group", element: <PermissionRoleGroupAssign /> },
        { path: "permission/group/list", element: <PermissionRoleGroupList /> },
        { path: "permission/group/detail", element: <PermissionRoleGroupDetail /> },
        { path: "permission/feature/list", element: <PermissionFeatureList /> },
        { path: "permission/feature/detail", element: <PermissionFeatureDetail /> },
        { path: "application", element: <ApplicationList /> },
        { path: "application/detail", element: <ApplicationDetail /> },
        { path: "platform", element: <PlatformList /> },
        { path: "platform/detail", element: <PlatformDetail /> },
        { path: "session", element: <SessionList /> },
        { path: "session/detail", element: <SessionDetail /> },
      ],
    },
    {
      element: <LandingLayout />,
      children: [
        { path: "maintenance", element: <ErrorComponent error={ERROR_TYPE.maintenanceMode} /> },
        { path: "error", element: <ErrorComponent error={ERROR_TYPE.authentication} /> },
      ],
    },
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "/logout",
      element: <Login />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    { path: "/token-handler", element: <TokenHandler /> },
    {
      // All path that are not defined will be redirected to the login page
      path: "*",
      element: <Login />,
    },
  ]);
  return routes;
};

function Router() {
  return (
    <BrowserRouter>
      <InjectAxiosInterceptors />
      <AppRoute />
    </BrowserRouter>
  );
}

export default Router;
