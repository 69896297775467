import "./UserDetail.scss";

import defaultContent from "../../../content/userDetail";
import { useTranslation } from "react-i18next";
import Title from "../../../common/components/Title/Title";
import Section from "../../../common/components/Section/Section";
import { Input } from "../../../common/components/Input/Input";
import Button from "../../../common/components/Button/Button";
import SelectComponent from "../../../common/components/Select/Select";
import { mockMemberType, mockStatus, mockTeam, mockUser } from "../../../mocks/data";

export const UserDetail = () => {
  const { t } = useTranslation(["userDetail"]);
  const title = t("userDetail", defaultContent["userDetail"]).toString();

  const userDetail = (
    <div className="container">
      <Title text={title} />
      <div className="h-3"></div>
      <div className="row">
        <div className="column-one">
          <Section text={"User Information"} />
        </div>
      </div>
      <div className="h-2"></div>
      <div className="row">
        <div className="column-three">
          <Input label={"Full Name"} readOnly={false} />
        </div>
        <div className="column-three">
          <Input label={"Email Address"} readOnly={false} />
        </div>
        <div className="column-three">
          <Input label={"Native ID"} readOnly={false} />
        </div>
      </div>
      <div className="h-1"></div>
      <div className="row">
        <div className="column-three">
          <Input label={"Country Code"} readOnly={false} />
        </div>
        <div className="column-three">
          <Input label={"Affiliate Code"} readOnly={false} />
        </div>
        <div className="column-three">
          <Input label={"Upline ID"} readOnly={false} />
        </div>
      </div>
      <div className="h-1"></div>
      <div className="row">
        <div className="column-three">
          <SelectComponent isMulti={false} label={"Status"} options={mockStatus} />
        </div>
      </div>
      <div className="h-3"></div>
      <div className="row">
        <div className="column-one">
          <Section text={"Team Information"} />
        </div>
      </div>
      <div className="h-1"></div>
      <div className="row">
        <div className="column-three">
          <SelectComponent isMulti={false} label={"Assigned Team"} options={mockTeam} />
        </div>
        <div className="column-three">
          <SelectComponent isMulti={false} label={"Member Type"} options={mockMemberType} />
        </div>
        <div className="column-three">
          <SelectComponent isMulti={false} label={"Team Member Status"} options={mockStatus} />
        </div>
      </div>
      <div className="h-3"></div>
      <div className="row">
        <div className="column-one">
          <Section text={"Delegation Information"} />
        </div>
      </div>
      <div className="h-1"></div>
      <div className="row">
        <div className="column-one">
          <SelectComponent isMulti={true} label={"Delegated User"} options={mockUser} />
        </div>
      </div>
      <div className="h-3"></div>
      <div className="row">
        <div className="column-one">
          <Button
            text="Save Changes"
            color="primary-purple"
            disabled={false}
            action={function (): void {
              throw new Error("Function not implemented.");
            }}
          />
          <span className="spacing-one"></span>
          <Button
            text="Cancel"
            color="primary-white"
            disabled={false}
            action={function (): void {
              throw new Error("Function not implemented.");
            }}
          />
        </div>
      </div>
      <div className="h-3"></div>
    </div>
  );

  return <>{userDetail}</>;
};

export default UserDetail;
