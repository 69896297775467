import "./TeamList.scss";

import defaultContent from "../../../content/teamList";
import { useTranslation } from "react-i18next";
import Title from "../../../common/components/Title/Title";
import Search from "../../../common/components/Search/Search";
import Button from "../../../common/components/Button/Button";
import Table from "../../../common/components/Table/Table";
import { mockTeamListTableData, mockTeamListTableHeader } from "../../../mocks/data";

export const TeamList = () => {
  const { t } = useTranslation(["teamList"]);
  const title = t("teamList", defaultContent["teamList"]).toString();

  // Separate constant for the container content
  const teamList = (
    <div className="container">
      <Title text={title} />
      <div className="h-3"></div>
      <div className="row">
        <div className="column-one right">
          <span className="w-3">
            <Search
              onSearch={function (query: string): void {
                throw new Error("Function not implemented.");
              }}
            />
          </span>

          <span className="spacing-half-one"></span>
          <Button
            text="Add Team"
            color="primary-black"
            disabled={false}
            action={function (): void {
              alert("Function not implemented.");
            }}
          />
          <span className="spacing-half-one"></span>

          <Button
            text="Bulk Process"
            color="primary-black"
            disabled={false}
            action={function (): void {
              alert("Function not implemented.");
            }}
          />
        </div>
      </div>
      <div className="h-2"></div>
      <Table headers={mockTeamListTableHeader} data={mockTeamListTableData} />
    </div>
  );

  return <>{teamList}</>;
};

export default TeamList;
