import { useTranslation } from "react-i18next";
import "./Login.scss";
import { Footer } from "../../../common/components/Footer/Footer";
import { Header } from "../../../common/components/Header/Header";
import defaultContent from "../../../content/login";
import useLogin from "../../CustomHooks/useLogin";

const Login = (_props: any) => {
  const { onButtonClick, isLoading, isError } = useLogin();

  const { t } = useTranslation(["header"]);

  return (
    <div>
      <Header showNavigation={false} />
      <div className="container">
        <div className="login-container-body">
          <div className="login-container">
            <p className="login-text">{`${t("loginText", defaultContent["loginText"])}`}</p>
            <button className="login-button" onClick={onButtonClick}>
              {`${t("login", defaultContent["login"])}`}{" "}
            </button>
          </div>
        </div>
      </div>
      {isError && <div>{`${t("something_went_wrong", defaultContent["something_went_wrong"])}`}</div>}
      <Footer />
    </div>
  );
};

export default Login;
