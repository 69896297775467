const content: { [key: string]: any } = {
  lgsLabel: "Account Manager Workspace",
  hi: "Hi",
  admin: "Admin",
  accountManagement: "Account Management",
  hatv: "hatv",
  bcr: "bcr",
  help: "help",
  english: "english",
  signout: "Sign Out",
};

export default content;
