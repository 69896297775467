import "./Button.scss";

type Props = {
  text: string;
  color: string;
  disabled: boolean;
  action: () => void;
  icon?: string;
  iconPosition?: "left" | "right";
  size?: "small" | "normal" | "large";
  border?: boolean;
};

const Button = ({
  text,
  action,
  color,
  disabled,
  icon,
  iconPosition = "left",
  size = "normal",
  border = true,
}: Props) => {
  const buttonClasses = `button ${color} ${disabled ? "disabled" : ""} ${size} ${border ? "with-border" : ""}`;

  return (
    <button className={buttonClasses} disabled={disabled} onClick={action}>
      {iconPosition === "left" && icon && (
        <div className="icon-container icon-left">
          <img src={icon} alt="Icon" className="icon" />
        </div>
      )}
      {text}
      {iconPosition === "right" && icon && (
        <div className="icon-container icon-right">
          <img src={icon} alt="Icon" className="icon" />
        </div>
      )}
    </button>
  );
};

export default Button;
