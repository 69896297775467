// Title.js
import "./Title.scss";

type Props = {
  text: string;
  size?: "small" | "medium" | "large"; // Add size prop
};

const Title = ({ text, size = "large" }: Props) => {
  const titleClassName = `title title-${size}`; // Include size in the class name

  return <h2 className={titleClassName}>{text}</h2>;
};

export default Title;
