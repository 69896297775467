// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Select, { Props as ReactSelectProps } from "react-select";
import "./Select.scss"; // Import the SCSS stylesheet

export type Option = {
  value: string;
  label: string;
  title?: string;
  type: string;
};

type CommonProps = {
  label?: string;
  options: Option[];
  defaultValue?: Option;
  isClearable?: boolean;
  isRtl?: boolean;
  isSearchable?: boolean;
  name?: string;
  isLoading?: boolean;
};

type MultiSelectProps = CommonProps & {
  isMulti?: boolean;
};

const SelectComponent = (props: MultiSelectProps) => {
  const {
    options,
    label,
    isMulti = false,
    defaultValue,
    isClearable = false,
    isRtl = false,
    isSearchable = false,
    name,
    isLoading = false,
  } = props;
  const defaultOption = defaultValue && options ? options.find((opt) => opt.value === defaultValue.value) : null;

  return (
    <div className="input">
      {label && <label className="input__label">{label}</label>}
      <Select
        options={options}
        isMulti={isMulti}
        className={isMulti ? "basic-multi-select" : "basic-select"}
        classNamePrefix="select"
        isClearable={isClearable}
        isRtl={isRtl}
        isSearchable={isSearchable}
        name={name}
        defaultValue={defaultOption ? defaultOption : null}
        isLoading={isLoading}
      />
    </div>
  );
};

export default SelectComponent;
