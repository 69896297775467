import "./Header.scss";
import { useTranslation } from "react-i18next";
import { HttpStatusCode } from "axios";
import defaultContent from "../../../content/header";
import config from "../../../config";
import { QUERY_STATUS } from "../../../constants";
import authService from "../../../utils/auth.service";
import axiosInstance from "../../../interceptors/axios.interceptor";

interface HeaderProps {
  showNavigation?: boolean;
  showHeader?: boolean;
  loggedUser?: string;
}

export const Header = ({ showNavigation = true, showHeader = false, loggedUser = "" }: HeaderProps) => {
  const onchangeHeaderTop = (value: any) => {
    if (value === "logout") {
      const url = `${config.appUrl}/auth/logout`;
      axiosInstance
        .get(url)
        .then((res) => {
          if (res && res.data) {
            let result = JSON.parse(JSON.stringify(res.data));
            if (result && result.statusCode === HttpStatusCode.Ok) {
              if (result.head && result.head.authentication) {
                const auth = result.head.authentication;
                if (auth.loginSessionStatus === QUERY_STATUS.LOGOUT && auth.userSessionStatus === QUERY_STATUS.LOGOUT) {
                  authService.clearAuth();
                  window.location.href = "/login";
                  return;
                }
              }
            }
          }
          authService.clearAuth();
          window.location.href = "/login";
        })
        .catch((err) => {
          console.log(`Logout error: ${err}`);
          authService.clearAuth();
          window.location.href = "/login";
        });
    }
  };

  const HeaderTop = ({ showHeader, loggedUser }: HeaderProps) => {
    const { t } = useTranslation(["header"]);

    return (
      <div className="header__top">
        <div className="header__top__left">{`${t("lgsLabel", defaultContent["lgsLabel"])}`}</div>
        {showHeader ? (
          <div className="header__top__right">
            <select className="header__top__right__language">
              <option>{`${t("english", defaultContent["english"])}`}</option>
            </select>

            <select className="header__top__right__help">
              <option>{`${t("help", defaultContent["help"])}`}</option>
            </select>

            <select className="header__top__right__user" onChange={(e) => onchangeHeaderTop(e.target.value)}>
              <option value="">{`${t("hi", defaultContent["hi"])}, ${loggedUser}`}</option>
              <option value="logout">{`${t("signout", defaultContent["signout"])}`}</option>
            </select>
          </div>
        ) : (
          <div />
        )}
      </div>
    );
  };

  const HeaderBottom = ({ showNavigation }: HeaderProps) => {
    return (
      <div className="header__bottom">
        <div className="header__bottom__left">
          <div className="header__bottom__left__logo">
            <img src="/img/logo-amway.png" alt="Amway Logo" />
          </div>
          {showNavigation && <ComponentDropdown />}
        </div>
      </div>
    );
  };

  const ComponentDropdown = () => {
    const { t } = useTranslation(["header"]);

    return (
      <div className="header__bottom__right__component">
        <select>
          <option>{`${t("admin", defaultContent["admin"])}`}</option>
          <option>{`${t("accountManagement", defaultContent["accountManagement"])}`}</option>
          <option>{`${t("hatv", defaultContent["hatv"])}`}</option>
          <option>{`${t("bcr", defaultContent["bcr"])}`}</option>
        </select>
      </div>
    );
  };

  return (
    <header>
      <HeaderTop showHeader={showHeader} loggedUser={loggedUser} />
      <HeaderBottom showNavigation={showNavigation} />
    </header>
  );
};
