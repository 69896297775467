import { useServiceAvailability } from "./components/CustomHooks/useServiceAvailability";
import Router from "./routes/Router";
import ErrorComponent from "./components/Error/Error";
import { ERROR_TYPE } from "./constants";

function App() {
  const { isServiceAvailable } = useServiceAvailability();

  return (
    <div>
      {String(isServiceAvailable) === "true" ? <Router /> : <ErrorComponent error={ERROR_TYPE.maintenanceMode} />}
    </div>
  );
}

export default App;
