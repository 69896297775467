import { jwtDecode } from "jwt-decode";
import { QUERY_STATUS } from "../constants";

const authService = {
  clearAuth() {
    authService.setNativeId(null);
    localStorage.removeItem("auth-accessToken");
    localStorage.removeItem("auth-nativeId");
  },

  setNativeId(nativeId: any) {
    localStorage.setItem("auth-nativeId", nativeId);
  },

  decodeAccessToken(accessToken: any): any {
    return jwtDecode(accessToken);
  },

  handleTokenResponse(result: any) {
    let accessToken;
    if (result && result.head && result.head.authentication) {
      const auth = result.head.authentication;
      if (
        auth.loginSessionStatus === QUERY_STATUS.ACTIVE &&
        auth.userSessionStatus === QUERY_STATUS.ACTIVE &&
        result.body
      ) {
        accessToken = result.body;
      }
    }
    localStorage.setItem("auth-accessToken", accessToken);
    const decodedAccessToken: any = authService.decodeAccessToken(accessToken);
    if (decodedAccessToken) {
      authService.setNativeId(decodedAccessToken["NativeId"]);
    }
    return accessToken;
  },

  isAuthenticated() {
    const token = localStorage.getItem("auth-accessToken");
    if (token) {
      let decodedAccessToken = authService.decodeAccessToken(token);
      if (decodedAccessToken) {
        return !authService.isAuthorizationExpired(decodedAccessToken["LoginSessionExpiryTime"]);
      }
    }

    return false;
  },

  isAccessTokenApiUrl(url: string): boolean {
    return (
      !url.includes("/refresh/token") &&
      !url.includes("/refresh/session") &&
      !url.includes("/logout") &&
      !url.includes("/auth/authorize") &&
      !url.includes("/auth/token") &&
      !url.includes("/availability")
    );
  },

  isAccessTokenNeedsRefresh() {
    let isExpired: boolean = false;
    const token = localStorage.getItem("auth-accessToken");
    if (token) {
      let decodedAccessToken = authService.decodeAccessToken(token);
      if (decodedAccessToken) {
        //as long as session login token still active, refresh access token
        isExpired = authService.isAuthorizationRefresh(
          decodedAccessToken["UserSessionRefreshTime"],
          decodedAccessToken["LoginSessionRefreshTime"],
        );
      }
    }
    //console.log(`isAccessTokenNeedsRefresh isexpired: ${isExpired}`);
    return isExpired;
  },

  isSessionTokenNeedsRefresh() {
    let isExpired: boolean = false;
    const token = localStorage.getItem("auth-accessToken");
    if (token) {
      let decodedAccessToken = authService.decodeAccessToken(token);
      if (decodedAccessToken) {
        //as long as session login token still active, refresh access token
        isExpired = authService.isAuthorizationRefresh(
          decodedAccessToken["LoginSessionRefreshTime"],
          decodedAccessToken["LoginSessionExpiryTime"],
        );
      }
    }
    return isExpired;
  },

  isAuthorizationRefresh(refresh: any, expiry: any) {
    if (refresh === null || expiry === null || refresh === "undefined" || expiry === "undefined") {
      return false;
    }

    const currentUnix = new Date(new Date().toUTCString()).getTime();
    const needRefresh = currentUnix >= parseInt(refresh) && currentUnix <= parseInt(expiry);
    return needRefresh;
  },

  isAuthorizationExpired(expiry: any) {
    if (expiry === null || expiry === "undefined") {
      return true;
    }

    const currentUnix = new Date(new Date().toUTCString()).getTime();
    const expired = currentUnix > parseInt(expiry);
    return expired;
  },
};

export default authService;
