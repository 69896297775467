import "./ApplicationList.scss";

import defaultContent from "../../../content/applicationList";
import { useTranslation } from "react-i18next";
import Title from "../../../common/components/Title/Title";
import Table from "../../../common/components/Table/Table";
import Search from "../../../common/components/Search/Search";
import Button from "../../../common/components/Button/Button";
import { mockApplicationListTableData, mockApplicationListTableHeader } from "../../../mocks/data";

export const ApplicationList = () => {
  const { t } = useTranslation(["applicationList"]);
  const title = t("applicationList", defaultContent["applicationList"]).toString();

  const applicationList = (
    <div className="container">
      <Title text={title} />
      <div className="h-3"></div>
      <div className="row">
        <div className="column-one right">
          <span className="w-3">
            <Search
              onSearch={function (query: string): void {
                throw new Error("Function not implemented.");
              }}
            />
          </span>

          <span className="spacing-half-one"></span>
          <Button
            text="Add Application"
            color="primary-black"
            disabled={false}
            action={function (): void {
              alert("Function not implemented.");
            }}
          />
          <span className="spacing-half-one"></span>

          <Button
            text="Bulk Process"
            color="primary-black"
            disabled={false}
            action={function (): void {
              alert("Function not implemented.");
            }}
          />
        </div>
      </div>
      <div className="h-2"></div>
      <Table headers={mockApplicationListTableHeader} data={mockApplicationListTableData} />
      <div className="h-3"></div>
    </div>
  );

  return <>{applicationList}</>;
};

export default ApplicationList;
