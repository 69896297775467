import { useEffect, useState } from "react";
import { HttpStatusCode } from "axios";
import "./Admin.scss";
import Sidebar from "../../common/components/Sidebar/Sidebar";
import config from "../../config";
import axiosInstance from "../../interceptors/axios.interceptor";

const Admin = () => {
  const [loggedUser, setLoggedUser] = useState("");

  useEffect(() => {
    const getActiveUsername = () => {
      let username = "";
      const nativeId = localStorage.getItem("auth-nativeId");
      if (nativeId) {
        const url = `${config.appUrl}/auth/user/native/${nativeId}`;
        axiosInstance
          .get(url)
          .then((res) => {
            if (res && res.data && res.data.statusCode === HttpStatusCode.Ok && res.data.body) {
              setLoggedUser(res.data.body.fullName);
            }
          })
          .catch((err) => {
            console.log(`Get user by native id: ${nativeId} error: ${err}`);
          });
      }

      return username;
    };
    getActiveUsername();
  }, []);

  return (
    <div>
      <Sidebar />
    </div>
  );
};

export default Admin;
