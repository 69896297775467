import "./TeamDetail.scss";

import defaultContent from "../../../content/teamDetail";
import { useTranslation } from "react-i18next";
import Title from "../../../common/components/Title/Title";
import Section from "../../../common/components/Section/Section";
import { Input } from "../../../common/components/Input/Input";
import Button from "../../../common/components/Button/Button";
import SelectComponent from "../../../common/components/Select/Select";
import { mockStatus, mockUser } from "../../../mocks/data";

export const TeamDetail = () => {
  const { t } = useTranslation(["teamDetail"]);
  const title = t("teamDetail", defaultContent["teamDetail"]).toString();

  const teamDetail = (
    <div className="container">
      <Title text={title} />
      <div className="h-3"></div>
      <div className="row">
        <div className="column-one">
          <Section text={"Team Information"} />
        </div>
      </div>
      <div className="h-1"></div>
      <div className="row">
        <div className="column-two">
          <Input label={"Team Name"} readOnly={false} />
        </div>
        <div className="column-two">
          <SelectComponent isMulti={false} label={"Status"} options={mockStatus} />
        </div>
      </div>
      <div className="h-3"></div>
      <div className="row">
        <div className="column-one">
          <Section text={"Assign Members"} />
        </div>
      </div>
      <div className="h-1"></div>
      <div className="row">
        <div className="column-one">
          <SelectComponent isMulti={true} label={"Lead Members"} options={mockUser} />
        </div>
      </div>
      <div className="h-1"></div>
      <div className="row">
        <div className="column-one">
          <SelectComponent isMulti={true} label={"Team Members"} options={mockUser} />
        </div>
      </div>
      <div className="h-2"></div>
      <div className="row">
        <div className="column-one">
          <Button
            text="Save Changes"
            color="primary-purple"
            disabled={false}
            action={function (): void {
              throw new Error("Function not implemented.");
            }}
          />
          <span className="spacing-one"></span>
          <Button
            text="Cancel"
            color="primary-white"
            disabled={false}
            action={function (): void {
              throw new Error("Function not implemented.");
            }}
          />
        </div>
      </div>
      <div className="h-3"></div>
    </div>
  );

  return <>{teamDetail}</>;
};

export default TeamDetail;
