import React, { useState } from "react";
import "./Table.scss"; // Import the SCSS stylesheet

type TableProps = {
  headers: string[];
  data: any[][];
};

const Table = ({ headers, data }: TableProps) => {
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const toggleSelectAll = () => {
    setSelectAll((prev) => !prev);
    setSelectedRows((prev) =>
      prev.length === data.length ? [] : Array.from({ length: data.length }, (_, index) => index),
    );
  };

  const toggleSelectRow = (rowIndex: number) => {
    setSelectedRows((prev) => (prev.includes(rowIndex) ? prev.filter((row) => row !== rowIndex) : [...prev, rowIndex]));
  };

  return (
    <div className="table-container">
      <table className="table">
        <thead>
          <tr>
            <th>
              <input className="table-checkbox-th" type="checkbox" checked={selectAll} onChange={toggleSelectAll} />
            </th>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              <td>
                <input
                  className="table-checkbox-td"
                  type="checkbox"
                  checked={selectedRows.includes(rowIndex)}
                  onChange={() => toggleSelectRow(rowIndex)}
                />
              </td>
              {row.map((cell, cellIndex) => (
                <td key={cellIndex}>{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
