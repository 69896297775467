import "./Input.scss";

interface InputProps {
  label: string;
  value?: string;
  placeholder?: string; // Add optional placeholder prop
  readOnly: boolean;
}

export const Input = ({ label, value, placeholder, readOnly }: InputProps) => {
  return (
    <div className="input">
      <label className="input__label">{label}</label>
      <input className="input__form" type="text" value={value} placeholder={placeholder} readOnly={readOnly} />
    </div>
  );
};
